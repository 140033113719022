/**
 * Application entry point
 */
require("babel-core/register");
require("babel-polyfill");
// Load application styles
import 'styles/index.scss';

// ================================
// START YOUR APP HERE
// ================================

const flkty = require('flickity');
/**
 * Header slider
 */
window.onload = () => {

    if (window.addTopOffersToIndexPage) {
      window.addTopOffersToIndexPage();
    }

    /**
    * Item Slider Init
    */
    (() => {
      if (document.querySelector('.item-slider')) {

        const slider = new flkty('.item-slider', {
          cellAlign: 'center',
          wrapAround: true,
          adaptiveHeight: true,
          percentPosition: true  

        });

        const goBackButton = document.body.appendChild(document.createElement('div'));
        goBackButton.classList.add('go-back-btn');
        goBackButton.addEventListener('click', () => history.back());

        const goToGalleryButton = document.body.appendChild(document.createElement('div'));
        goToGalleryButton.classList.add('go-back-btn');
        goToGalleryButton.classList.add('gallery');
        goToGalleryButton.addEventListener('click', () => document.getElementById('siema').scrollIntoView({block: 'start', behavior: 'smooth'}));
      }
    })();

}

/**
 * Navigation Active Class
 */
(() => {
  const activeNavIndex = Number(document.querySelector('.hidden--nav-index').textContent.trim());
  const navItems = document.querySelectorAll('.header .nav a');
  navItems[activeNavIndex].classList.add('active');
})();

/**
 * Navigation Hover Class
 */
(() => {
  document.querySelectorAll('.header .nav a').forEach(link => {
    link.addEventListener('mouseover', () => {
      link.classList.add('once-hovered');
    }, {
      once: true
    });
  });
  document.querySelectorAll('.footer-nav a').forEach(link => {
    link.addEventListener('mouseover', () => {
      link.classList.add('once-hovered');
    }, {
      once: true
    });
  });
})();

/**
 * Top Offers at Index Page
 */
(() => {
  if (location.pathname === '/' || location.pathname === '/index.html') {
    async function addTopOffersToIndexPage() {
      let html;
      const used = [];
      let container = document.getElementById('top-offers');
      function getRandom(list) {        
        if (!used.length) {
          let x = Math.floor(Math.random() * list.length);
          used.push(x);
          return x;
        } else {
          let x = Math.floor(Math.random() * list.length);
          for (let i = 0; i < used.length; i++) {
            if (x === used[i]) {
              return getRandom(list);
            }
          }
          used.push(x);
          return x;
        }
      }

      await fetch('./offers.html')
        .then(res => res.text())
        .then(data => {
          html = data;
        });
    
      await (() => {
        let list = html.split('<ul class="ad-list">')[1]
          .split('</ul>')[0]
          .split('<li>')
          .map(a => a.split('</li>')[0].trim())
          .filter(x => x !== "");
        
        const rand1 = getRandom(list);
        const rand2 = getRandom(list);
        const rand3 = getRandom(list);
        const rand4 = getRandom(list);

        container.innerHTML += `
          ${list[rand1]}
          ${list[rand2]}
          ${list[rand3]}
          ${list[rand4]}
        `;
         
        return list;
      })();   
          
    };
    window.addTopOffersToIndexPage = addTopOffersToIndexPage;
  }
  
})();

/**
 * Mobile Navigation 
 */
(() => {
  const menuButton = document.querySelector('#menu-button');

  document.body.addEventListener('click', e => {
    if (e.target.getAttribute('data-menu') === 'open') {
      e.preventDefault();
      changeAttributes('close');
      toggleMenu(true);
      menuButton.classList.toggle('on-menu');
    } else if (e.target.getAttribute('data-menu') === 'close') {
      e.preventDefault();
      changeAttributes('open');
      toggleMenu(false);
      menuButton.classList.toggle('on-menu');
    }
  });

  function changeAttributes(attribute) {
    const needed = document.querySelectorAll('[data-menu]');
    needed.forEach(elem => elem.setAttribute('data-menu', attribute));
  }

  function toggleMenu(state) {
    const nav = document.querySelector('.mobile-nav');
    state ? nav.classList.add('on-menu') : nav.classList.remove('on-menu');
  }
})();
